import React, { Component } from "react";
import "./MaintenancePage.scss";
import HeaderVisitor from "../../components/layouts/header-visitor/HeaderVisitor";

class MaintenancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }




  render() {
    return (
      <div className="under-maintenance app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
        <HeaderVisitor />
        <div id="content">
          <h1>🚧 Maintenance Technique 🚧</h1>
          <p>Le site est actuellement en maintenance, veuillez nous excuser pour la gêne occasionnée.</p>
        </div>
        <div id="outerCraneContainer">
          <div class="buildings">
            <div></div>
            <div class="1"></div>
            <div class="2"></div>
            <div class="3"></div>
            <div class="4"></div>
          </div>
          <div class="crane craneThree">
            <div class="line lineOne"></div>
            <div class="line lineTwo"></div>
            <div class="line lineThree"></div>
            <div class="stand"></div>
            <div class="weight"></div>
            <div class="cabin"></div>
            <div class="arm"></div>
          </div>
          <div class="crane craneTwo">
            <div class="line lineOne"></div>
            <div class="line lineTwo"></div>
            <div class="line lineThree"></div>
            <div class="stand"></div>
            <div class="weight"></div>
            <div class="cabin"></div>
            <div class="arm"></div>
          </div>
          <div class="crane craneOne">
            <div class="line lineOne"></div>
            <div class="line lineTwo"></div>
            <div class="line lineThree"></div>
            <div class="stand"></div>
            <div class="weight"></div>
            <div class="cabin"></div>
            <div class="arm"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default MaintenancePage;

